import { Supply, SupplyItem } from "src/entities/supply/Supply"
import { v4 as guid } from 'uuid'

const data: Supply = {
    id: guid(),
    supplierId: 0,
    deliveryDate: new Date(),
    comment: null,
    supplyDocument: null,
    transactionId: guid(),
    storageId: 0,
    paymentAmount: null,
    paymentDate: null,
    items: []
}
const supplyReducer = (state: Supply = data, action) => {
    switch (action.type) {
        case 'SET_SUPPLY':
            return { ...action.payload }

        case "SUPPLY_ADD_ITEM":
            {
                const supply: Supply = state
                const supplyItem: SupplyItem = action.payload

                supply.items = [...supply.items, supplyItem]
                return { ...supply }
            }

        case "SUPPLY_REMOVE_ITEM":
            {
                const supply: Supply = state
                const supplyItem: SupplyItem = action.payload

                supply.items = [...supply.items.filter(x => x.id !== supplyItem.id)]
                return { ...supply }
            }

        case "SUPPLY_UPDATE_ITEM":
            {
                const supply: Supply = state
                const supplyItem: SupplyItem = action.payload

                supply.items = [...supply.items.filter(x => x.id !== supplyItem.id), supplyItem]
                return { ...supply }
            }

        default:
            return state
    }
}

export default supplyReducer