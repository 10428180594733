import { PreferredCustomer } from "src/entities/marketing/PreferredCustomer"

const data: PreferredCustomer[] = []
const loyaltyPreferredCustomersOptionsReducer = (state: PreferredCustomer[] = data, action) => {
    switch (action.type) {
        case "PREFERRED_CUSTOMERS":
            return [...action.payload]

        default:
            return state
    }
}

export default loyaltyPreferredCustomersOptionsReducer