import { Preparation, PreparationItem } from "src/entities/preparation/Preparation"

const data: Preparation = {
    id: "",
    preparationName: "",
    howToPrepare: "",
    imagePath: null,
    unitId: 0,
    isActive: false,
    receipt: []
}
const preparationReducer = (state: Preparation = data, action) => {
    switch (action.type) {
        case 'SET_PREPARATION':
            return action.payload

        case 'ADD_PREPARATION_RECEIPT_ITEM':
            {
                const preparation: Preparation = state
                const item: PreparationItem = action.payload

                preparation.receipt = [...preparation.receipt, item]

                return { ...preparation }
            }

        case 'REMOVE_PREPARATION_RECEIPT_ITEM':
            {
                const preparation: Preparation = state
                const item: PreparationItem = action.payload

                preparation.receipt = [...preparation.receipt.filter(x => x.id !== item.id)]

                return { ...preparation }
            }

        case 'UPDATE_PREPARATION_RECEIPT_ITEM':
            {
                const preparation: Preparation = state
                const item: PreparationItem = action.payload

                preparation.receipt = [...preparation.receipt.filter(x => x.id !== item.id), item]

                return { ...preparation }
            }

        default:
            return state
    }
}

export default preparationReducer