import { StackingOption } from "src/entities/marketing/StackingOption"
import { Category } from "src/entities/product/ProductAndCategory"

const data: Category[] = []
const categoryGroupedReducer = (state: Category[] = data, action) => {
    switch (action.type) {
        case 'SET_GROUPED_ITEMS':      
            return [...action.payload]
        default:
            return state
    }
}

export default categoryGroupedReducer