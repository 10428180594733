import { Customer, CustomerAddress } from "src/entities/customer/Customer"
import { v4 as guid } from 'uuid'

const address: CustomerAddress = {
    id: guid(),
    orderNo: 1
}

const data: Customer = {
    id: guid(),
    name: "",
    surname: "",
    addresses: [address]
}
const customerReducer = (state: Customer = data, action) => {
    switch (action.type) {
        case "CUSTOMER_INIT":
            const customer: Customer = action.payload

            if (customer.addresses?.length === 0) customer.addresses = [address]
            return customer

        case "CUSTOMER_RESET":
            return data

        case "CUSTOMER_UPDATE":
            return {
                ...state,
                name: action.payload.name,
                surname: action.payload.surname,
                birthday: action.payload.birthday,
                gender: action.payload.gender,
                mobile: action.payload.mobile,
                bonus: action.payload.bonus,
                email: action.payload.email,
                comment: action.payload.comment,
                addresses: [...action.payload.addresses]
            }

        case "CUSTOMER_ADD_ADDRESS":
            return state.addresses !== undefined ? { ...state, addresses: [...state.addresses, action.payload] } : { ...state, addresses: [action.payload] }

        case "CUSTOMER_REMOVE_ADDRESS":
            {
                const addresses: CustomerAddress[] | undefined = state.addresses?.filter(x => x.id !== action.payload.id)
                return { ...state, addresses: [...addresses!] }
            }

        case "CUSTOMER_UPDATE_ADDRESS":
            {
                const addresses: CustomerAddress[] = [...state.addresses.filter(x => x.id !== action.payload.id)]
                return { ...state, addresses: [...addresses, action.payload] }
            }

        case "CUSTOMER_RESET":
            return { ...data }

        default:
            return state
    }
}

export default customerReducer