// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import loyaltyInfoReducer from './marketing/loyalty'
import customerGroupReducer from './customerGroup'
import promotionInfoReducer from './marketing/promotion'
import loyaltyStackingOptionsReducer from './marketing/stackingOptions'
import categoryGroupedReducer from './marketing/categoryGrouped'
import loyaltyPreferredCustomersOptionsReducer from './marketing/preferredCustomer'
import loyaltyRewardTypesReducer from './marketing/rewardTypes'
import customerReducer from './customer/customer'
import dishReducer from './menu/dish'
import dropDownOptionsReducer from "./menu/ItemsDropDown"
import preparationReducer from './menu/preparation'
import ingredientReducer from './menu/ingredient'
import categoryReducer from './menu/category'
import supplyReducer from './supply/supply'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  loyaltyInfoReducer,
  customerGroupReducer,
  promotionInfoReducer,
  loyaltyStackingOptionsReducer,
  categoryGroupedReducer,
  loyaltyPreferredCustomersOptionsReducer,
  loyaltyRewardTypesReducer,
  customerReducer,
  dishReducer,
  preparationReducer,
  ingredientReducer,
  categoryReducer,
  supplyReducer,
  dropDownOptionsReducer
})

export default rootReducer
